/* eslint-disable react-hooks/rules-of-hooks */
import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IArquivoCleanDTO, IMaterialApoioDTO, IPastaCleanDTO } from '../../../../models/happy-code-api.model';
import { useMaterialApoioService } from '../../../../services/materiais.apoio.service';
import { useNotificationService } from '../../../../services/notification.service';
import { token } from '../../../../util/store';
import { usePastaService } from '../../../../services/pasta.service';

export default function AcademyMaterialApoioCreate() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [materialApoio, setMaterialApoio] = useState<IMaterialApoioDTO>();
	const [pasta, setPasta] = useState<IPastaCleanDTO>();
	const { idMaterialApoio } = useParams<any>();
	const { idPasta } = useParams<any>();
	const { setIsGlobalLoading } = useGlobalContext();
	const materialService = useMaterialApoioService();
	const pastaService = usePastaService();
	const notification = useNotificationService();

	const useFindMaterialApoio = () => {
		setIsGlobalLoading(true);
		materialService
			.findById({ id: idMaterialApoio })
			.then(({ data }) => setMaterialApoio(data))
			.finally(() => setIsGlobalLoading(false));
	};

	const useFindPasta = () => {
		setIsGlobalLoading(true);
		pastaService
			.findCleanById({ id: idPasta })
			.then(({ data }) => {
				setPasta(data); 
				setMaterialApoio((prev) => ({ ...prev, pasta: data })); 
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idMaterialApoio) useFindMaterialApoio();
		if (idPasta) useFindPasta();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const patchMaterialDeApoio = () => {
		setIsGlobalLoading(true);
		materialService
			.patch(materialApoio)
			.then(() => {
				notification({
					description: `Arquivo de apoio editado com sucesso!`,
					type: 'success',
					message: 'Sucesso',
				});
				history.back();
			})
			.finally(() => setIsGlobalLoading(false));
	};
	const createMaterialDeApoio = () => {
		if (idMaterialApoio) return patchMaterialDeApoio();
		setIsGlobalLoading(true);
		materialService
			.create(materialApoio)
			.then(() => {
				notification({
					description: `Arquivo de apoio editado com sucesso!`,
					type: 'success',
					message: 'Sucesso',
				});
				history.back();
			})
			.finally(() => setIsGlobalLoading(false));
	};


	const submitValidationFields = (e) => {
		e.preventDefault();
		if (!materialApoio?.descricao) {
			notification({
				description: `Por favor, preencha o campo descricao!`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if(!materialApoio.arquivo){
			notification({
				description: `Por favor, selecione um arquivo corretamente para o material de apoio!`,
				type: 'warning',
				message: 'Atenção!',

			});
			return;
		}

		createMaterialDeApoio();
	};

	const beforeUpload = (file: UploadFile) => {
		if (file.size > 100 * 1024 * 1024) {
			 notification({
				description: `O tamanho do arquivo deve ser menor que 100MB!`,
				type: 'warning',
				message: 'Atenção!',
			});
			file.status = 'error'
			file.response = 'O tamanho do arquivo deve ser menor que 100MB!'
			
			return false
		}
		return true
	};
	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response:IArquivoCleanDTO = file.response;
			setMaterialApoio({ ...materialApoio, arquivo: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setMaterialApoio({ ...materialApoio, arquivo: null });
			notification({
				description: `${file.name} removido com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			})
		}
	};

	
	return (
		<div className="container-fondo">
			<Header />
			<form onSubmit={(e) => submitValidationFields(e)}>
				<div className="franquia" style={{ left: 0 }}>
					<div className="user-row">
						<div className="card-form">
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
									Insira informações para {idMaterialApoio ? 'edição' : 'cadastro'} de material de apoio
								</h3>
								<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined rev={undefined} />} />
							</div>
							<div className="input-box">
								<label htmlFor="dsArquivo">Descrição</label>
								<Input
									id="dsArquivo"
									maxLength={35}
									placeholder="Ex.: Esse arquivo trata sobre..."
									value={materialApoio && materialApoio.descricao}
									defaultValue={materialApoio && materialApoio.descricao}
									onChange={(e) => setMaterialApoio({ ...materialApoio, descricao: e.target.value })}
								/>
							</div>

							<div className="input-box">
								<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
									<label>Arquivo</label>
									<small style={{ marginTop: 10 }}>{materialApoio?.arquivo?.codigo}</small>
									<div style={{ textAlign: 'center', width: '100%' }}>
										<Dragger
											headers={{ Authorization: `Bearer ${token()}` }}
											action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload`}
											onChange={onChange}
											maxCount={1}
											beforeUpload={beforeUpload}
											
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined rev={undefined} />
											</p>
											<p className="ant-upload-text">Clique ou arraste o arquivo para este área</p>
											<p className="ant-upload-hint">
												Tamanho máximo do arquivo suportado: 100MB
											</p>
										</Dragger>
										
									</div>
								</div>
							</div>
							<div className="botoes" style={{ marginTop: 10 }}>
								<Link to={`/academy/gestao-pasta-material`}>
									<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
										Voltar
									</Button>
								</Link>
								<Button style={{ width: 345 }} type="primary" htmlType="submit" id="proximo" className="button-primary">
									Salvar
								</Button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/academy/gestao-pasta-material`}
			/>
		</div>
	);
}
