import moment from 'moment';
import { ReportColumns } from '../models/relatorio.model';
import { IUnidadeCleanDTO, IUserAuthDTO } from '../models/happy-code-api.model';
import { PerfilEnum } from '../enum/perfil.enum';
import { FuncaoEnum } from '../enum/funcao.enum';
import { useUsuarioService } from '../services/usuario.service';

export const hoje = () => new Date();

export const verifyNull = (obj: any) =>
	Object.values(obj).every((o) => o === null);

export const getAge = (dateString: string | Date): number => {
	const today = new Date();
	const birthDate = new Date(dateString);
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

export const isThisWeek = (dt: string) => {
	const date = new Date(dt);
	const todayObj = new Date();
	const todayDate = todayObj.getDate();
	const todayDay = todayObj.getDay();

	const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

	return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

export const isToday = (dt: string) => moment(dt).isSame(Date.now(), 'day');

export const differenceInYears = (dt1: Date | string, dt2: Date | string) =>
	moment(dt1).diff(moment(dt2), 'years');

export const dateDifferenceText = (data: string | Date) => {
	if (!data) return '';
	const endingDate = new Date(data);
	let startDate = new Date();

	let endDate = new Date(endingDate);
	if (startDate > endDate) {
		const swap = startDate;
		startDate = endDate;
		endDate = swap;
	}
	const startYear = startDate.getFullYear();
	const february =
		(startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
			? 29
			: 28;
	const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	let yearDiff = endDate.getFullYear() - startYear;
	let monthDiff = endDate.getMonth() - startDate.getMonth();
	if (monthDiff < 0) {
		yearDiff--;
		monthDiff += 12;
	}
	let dayDiff = endDate.getDate() - startDate.getDate();
	if (dayDiff < 0) {
		if (monthDiff > 0) {
			monthDiff--;
		} else {
			yearDiff--;
			monthDiff = 11;
		}
		dayDiff += daysInMonth[startDate.getMonth()];
	}

	return yearDiff + ' Ano(s) ' + monthDiff + ' Mes(es) ' + dayDiff + ' Dia(s)';
};

export const exportColumnsRelatorio = (data) => {
	if (data && data.length > 0) {
		const headers = Object.keys(data[0]);
		return headers
			.filter((header) => header.includes('*'))
			.map((column) => {
				const columnRp = column.replace('*', '');
				const reportColumns: ReportColumns = {
					title: columnRp.toLocaleUpperCase(),
					dataIndex: column,
					key: column,
				};
				return reportColumns;
			});
	}
	return [];
};

export const reducao = (value: number, percentual: number) =>
	value * (1 - percentual / 100);

export const validateFileExtension = (filePath) => {
	const allowedExtensions = ['.jpg', '.jpeg', '.png', '.svg', '.gif'];
	const fileExtension = filePath?.slice(filePath.lastIndexOf('.')).toLowerCase();

	return !allowedExtensions.includes(fileExtension);
};

export const validateFileExtensionArquivo = (filePath) => {
	const allowedExtensions = [".zip", ".pdf", ".aia", ".pptx", ".capx", ".docx", ".exe", ".rar", ".png", ".mcworld", ".uf2", ".ev3", ".sb3", ".sjr"];
	const fileExtension = filePath?.slice(filePath.lastIndexOf('.')).toLowerCase();

	return !allowedExtensions.includes(fileExtension);
};

export const disabledDateAfterToday = (current: moment.Moment | null): boolean => {
	return current ? current.isAfter(moment().endOf('day')) : false;
};

// verificar se é gestor ou administrador
export const isGestorOrAdminSede = async (usuario: IUserAuthDTO, unidade: IUnidadeCleanDTO): Promise<boolean> => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { findTipoUnidadePessoaByParams } = useUsuarioService();
	if(!usuario.id || !unidade.id){
		return
	}
	const unidadePessoa = (await findTipoUnidadePessoaByParams(usuario.id, unidade.id)).data;

	if (usuario.perfil === PerfilEnum.SEDE && usuario.funcao === FuncaoEnum.ADMINISTRADOR_CODIGO) {
		return Promise.resolve(true);
	}

	if (unidadePessoa.tipoUnidadePessoaId === 2 && usuario.perfil === PerfilEnum.FRANQUIA) {
		return Promise.resolve(true);
	}
	return Promise.resolve(false);
};

