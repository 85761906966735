import * as Unicons from '@iconscout/react-unicons';
import { Button, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { IEstadoCleanDTO, IPaisCleanDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useLogradouroService } from '../../../services/logradouro.service';
import { useNotificationService } from '../../../services/notification.service';
import '../style.css';
import { useEstadoService } from './../../../services/estado.service';
import { usePaisService } from './../../../services/pais.service';
import StepsCreate from './steps-create';
import useValidationAntdHook from '../useValidationAntdHook';

export const FranquiaCreateStep2: React.FunctionComponent = () => {
	const { unidade, setUnidade, revisao } = useUnidadeContext();
	const { setIsGlobalLoading } = useGlobalContext();

	const [paisList, setPaisList] = useState<IPaisCleanDTO[]>();
	const [listEstado, setListEstado] = useState<IEstadoCleanDTO[]>();
	const [error, setError] = useState<string>('');
	const { validateNumber, validateLength } = useValidationAntdHook();

	const notification = useNotificationService();

	const history = useHistory();
	const [form] = Form.useForm();
	const paisService = usePaisService();
	const estadoService = useEstadoService();
	const logradouroService = useLogradouroService();

	const findPais = () => {
		const predicate = new Predicate();
		return paisService.findCleanList(predicate).then(({ data }) => {
			setPaisList(data);
		});
	};

	const findEstados = () => {
		const predicateEstado = new Predicate(100);
		return estadoService.findClean(predicateEstado).then(({ data }) => {
			setListEstado(data.content);
		});
	};

	useEffect(() => {
		const { endereco } = unidade;
		if (endereco) {
			form.setFieldsValue(endereco);
		}
	}, [form, unidade]);

	useEffect(() => {
		setIsGlobalLoading(true);
		Promise.all([findPais(), findEstados()]).finally(() => setIsGlobalLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUnidade = (franquiaCrud) => {
		franquiaCrud['uf'] = listEstado.find((estado) => estado.nome == franquiaCrud['nomeUF']).codigo;
		const contextState = { ...unidade, endereco: franquiaCrud };
		setUnidade(contextState);
		if (franquiaCrud.revisao === '1') {
			history.push('/franquias/create/step-10');
		} else {
			history.push('/franquias/create/step-3');
		}
	};

	const onCepChange = (cep: string) => {
		if (cep.length !== 8) {
			return;
		}
		setIsGlobalLoading(true);
		const predicateLogradouro = new Predicate(10);
		predicateLogradouro.addOption('cep', cep);
		logradouroService
			.findCustom(predicateLogradouro)
			.then(({ data }) => {
				if (data && data.length > 0) {
					const logradouro = data[0];
					const { endereco } = unidade;
					endereco.cep = logradouro.cep;
					endereco.bairro = logradouro.bairroNome;
					endereco.cidade = logradouro.cidadeNome;
					endereco.logradouro = logradouro.nomeCompleto;
					endereco.nomeUF = logradouro.cidadeEstadoNome;
					endereco.paisId = logradouro.cidadeEstadoPaisId;

					form.setFieldsValue(endereco);

					setError('');

					setUnidade({ ...unidade, endereco });
				} else {
					notification({ description: 'CEP não encontrado', type: 'warning', message: 'Aviso' });
					setError('CEP não encontrado!');
					form.resetFields();
				}
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return (
		<div className="container">
			<Header />
			<Progress />
			<StepsCreate current={1} />
			<div className="franquia" style={{ top: 70 }}>
				<Form form={form} name="control-hooks" onFinish={handleUnidade}>
					<div className="franquia-row">
						<h5>Onde fica a franquia?</h5>
						<Form.Item name="id" />
						<div className="input-franquia">
							<span>CEP</span>
							<Form.Item
								name="cep"
								rules={[
									{ required: true, message: 'Insira o CEP' },
									{
										validator: (rule, value, callback) => validateLength(rule, value, callback, 8),
									},
									{ validator: validateNumber },
								]}
							>
								<Input id="cep" onChange={(e) => onCepChange(e.target.value)} className="input" maxLength={8} />
							</Form.Item>
						</div>
						<div className="select-franquia">
							<span>País</span>
							<Form.Item name="paisId" rules={[{ required: true, message: 'Selecione um País' }]}>
								<Select id="pais" placeholder="Selecione um País" allowClear>
									{paisList &&
										paisList.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{item.nome}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						</div>
						<div className="select-franquia">
							<span>Bairro</span>
							<Form.Item name="bairro" rules={[{ required: true, message: 'Insira um Bairro' }]}>
								<Input id='bairro'/>
							</Form.Item>
						</div>
						<div className="box-cidade">
							<div className="select-franquia">
								<span>Estado</span>
								<Form.Item name="nomeUF" rules={[{ required: true, message: 'Selecione um Estado' }]}>
									<Select id="estado" placeholder="Selecione um Estado" allowClear>
										{listEstado &&
											listEstado.map((item) => (
												<Select.Option key={item.id} value={item.nome}>
													{item.nome}
												</Select.Option>
											))}
									</Select>
								</Form.Item>
							</div>
							<div className="select-franquia">
								<span>Cidade</span>
								<Form.Item name="cidade" rules={[{ required: true, message: 'Selecione uma Cidade' }]}>
									<Input id="cidade" className="input" />
								</Form.Item>
							</div>
						</div>
						<div className="box-complemento">
							<div className="input-franquia">
								<span>Logradouro</span>
								<Form.Item name="logradouro" rules={[{ required: true, message: 'Insira o Logradouro' }]}>
									<Input id="logradouro" className="input" />
								</Form.Item>
							</div>
							<div className="input-franquia" style={{ width: '17%' }}>
								<span>Número</span>
								<Form.Item
									name="numero"
									rules={[
										{ required: true, message: 'Insira o Número' },
                    { validator: validateNumber },
									]}
								>
									<Input id="numero" className="input" />
								</Form.Item>
							</div>
						</div>
						<div className="input-franquia">
							<span>Complemento</span>
							<Form.Item name="complemento">
								<Input id="complemento" className="input" />
							</Form.Item>
						</div>
						<div style={{ textAlign: 'center', width: '100%' }}>{error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}</div>
						<div className="botoes">
							{revisao ? (
								<>
									<Form.Item name="revisao" initialValue="1" />
									<Button type="primary" htmlType="submit" className="button-primary" id="revisao" style={{ width: 700 }} disabled={error ? true : false}>
										Voltar para Revisão
									</Button>
								</>
							) : (
								<>
									<Link to="/franquias/create">
										<Button className="button-second" id="voltar" style={{ width: 345 }}>
											Voltar
										</Button>
									</Link>
									<button type="submit" className="button-primary" id="proximo" style={{ width: 345 }} disabled={error ? true : false}>
										<span>Próximo</span>
									</button>
								</>
							)}
						</div>
					</div>
				</Form>
			</div>
			<Link to="/franquias">
				<div className="fechar">
					<Unicons.UilTimes size="20" color="#353F49" />
				</div>
			</Link>
		</div>
	);
};
