import { useCallback, useEffect, useState } from 'react';
import { useAlunoContext } from '../../../../../../context/AlunoContext';
import { IMatriculaCustomDTO, ITipoCurriculoDTO } from '../../../../../../models/happy-code-api.model';
import { Predicate } from '../../../../../../models/predicate.model';
import { useMatriculaService } from '../../../../../../services/matricula.service';
import { useTipoCurriculoService } from '../../../../../../services/tipo-curriculo.service';

export default function useStudentCourses(rematriculas: IMatriculaCustomDTO[]) {
	const { aluno } = useAlunoContext();
	const { findCustom } = useMatriculaService();
	const { findList } = useTipoCurriculoService();

	const [isLoading, setIsLoading] = useState(false);
	const [coursesData, setCoursesData] = useState<IMatriculaCustomDTO[]>([]);
	const [isMatricula, setIsMatricula] = useState(false);
	const [isRematricula, setIsRematricula] = useState(false);
	const [hasUpgradeAvailable, setHasUpgradeAvailable] = useState(false);
	const [regularResumes, setRegularResumes] = useState<number[]>([]);
	const [notregularResumes, setNotRegularResumes] = useState<number[]>([]);

	const addPredicateOnRequest = useCallback((): void | Predicate => {
		if (!aluno.id) return;

		const predicate = new Predicate(50);
		predicate.addOption('aluno.id', aluno.id);
		return predicate;
	}, [aluno.id]);

	const getAllCourses = useCallback(async (): Promise<void> => {
		setIsLoading(true);

		const predicate = addPredicateOnRequest();
		if (!predicate) return;

		await findCustom(predicate)
			.then(({ data }) => setCoursesData(data))
			.finally(() => setIsLoading(false));
	}, [addPredicateOnRequest]);

	const getAllTipoCurriculo = useCallback(async (): Promise<void> => {
		const response = await findList();
		const tiposCurriculo: ITipoCurriculoDTO[] = response.data;

		const regular = tiposCurriculo
			.filter(tc => ![1, 12].includes(tc.id))
			.map(tc => tc.id);

		const notRegular = tiposCurriculo
			.filter(tc => [1, 12].includes(tc.id))
			.map(tc => tc.id);
	
		setRegularResumes(regular);
		setNotRegularResumes(notRegular);
	}, [findList]);

	const checkMatricula = useCallback(() => {
		const firstMatricula = coursesData.length === 0;
		const regularCourses = coursesData.filter(({ turma }) =>
			turma?.curriculoNegocioTipoCurriculoCodigo === "RGL"
		);

		if (firstMatricula || regularCourses.length === 0) {
			setIsMatricula(true);
			return;
		}

		const preMatriculas = regularCourses.some(c => c.statusMatricula.id === 1);
		if (preMatriculas) return setIsMatricula(!preMatriculas);

		const finalizedMatriculas = regularCourses.every(({ statusMatricula }) => statusMatricula.id === 3);
		if (finalizedMatriculas) return setIsMatricula(finalizedMatriculas);

		const contractsRematricula = regularCourses
			.filter(({ statusMatricula }) => statusMatricula.id !== 3)
			.every(({ dataInicioContrato }) => {
				const dateContractEnded = new Date(dataInicioContrato);
				dateContractEnded.setFullYear(dateContractEnded.getFullYear() + 1);

				const today = new Date();
				const minLimit = new Date(dateContractEnded);
				minLimit.setDate(minLimit.getDate() - 120);

				const maxLimit = new Date(dateContractEnded);
				maxLimit.setDate(maxLimit.getDate() + 120);

				return today >= minLimit && today <= maxLimit;
			});

		setIsMatricula(!contractsRematricula);
	}, [coursesData]);

	const checkRematricula = useCallback(() => {
		if (coursesData.length === 0) {
			setIsRematricula(false);
			return;
		}

		const rematriculasCourses = coursesData.filter(
			({ rematricula, statusMatricula }) => rematricula === true && statusMatricula.id !== 3
		);

		if (rematriculas.length > 0) {
			const hasDoneRematriculas = rematriculasCourses.some(course =>
				rematriculas.map(r => r.financeiro?.id).includes(course.financeiroRematricula?.id)
			);
			setIsRematricula(!hasDoneRematriculas);
		}
	}, [coursesData, rematriculas]);

	const handleUpgradeAvailable = () => {
		setHasUpgradeAvailable(true);
	};

	useEffect(() => {
		const controller = new AbortController();

		getAllCourses();
		getAllTipoCurriculo();

		return () => controller.abort();
	}, [getAllCourses]);

	useEffect(() => {
		if (!isLoading && coursesData.length > 0) {
			checkMatricula();
			checkRematricula();
		}
	}, [coursesData, isLoading, checkMatricula, checkRematricula]);

	return {
		isLoading,
		coursesData,
		isMatricula,
		isRematricula,
		hasUpgradeAvailable,
		handleUpgradeAvailable,
		regularResumes,
		notregularResumes
	};
}
