import { Schedule } from '../../interfaces/offer/schedule.interface';

export function horaFim(hora: string, minuto: string, isMaringa = true) {
	let horaFinal;
	switch (parseInt(minuto)) {
		case 0:
			horaFinal = `${parseInt(hora)}`.padStart(2, '0') + (isMaringa ? ':30' : ':15');
			break;
		case 15:
			horaFinal = `${parseInt(hora)}`.padStart(2, '0') + (isMaringa ? ':45' : ':30');
			break;
		case 30:
			horaFinal = isMaringa ? `${parseInt(hora) + 1}`.padStart(2, '0') + ':00' : `${parseInt(hora)}`.padStart(2, '0') + ':45';
			break;
		case 45:
			horaFinal = `${parseInt(hora) + 1}`.padStart(2, '0') + (isMaringa ? ':15' : ':00');
			break;
	}
	return horaFinal;
}

export function horaFimForm(hora: string, minuto: string, isMaringa = true) {
	let horaFinal;
	switch (parseInt(minuto)) {
		case 0:
			horaFinal = `${parseInt(hora) + 1}`.padStart(2, '0') + (isMaringa ? ':30' : ':15');
			break;
		case 15:
			horaFinal = `${parseInt(hora) + 1}`.padStart(2, '0') + (isMaringa ? ':45' : ':30');
			break;
		case 30:
			horaFinal = isMaringa ? `${parseInt(hora) + 2}`.padStart(2, '0') + ':00' : `${parseInt(hora) + 1}`.padStart(2, '0') + ':45';
			break;
		case 45:
			horaFinal = isMaringa ? `${parseInt(hora) + 2}`.padStart(2, '0') + ':15' : `${parseInt(hora) + 2}`.padStart(2, '0') + ':00';
			break;
	}
	return horaFinal;
}

export function horarioDaGrade(primeiroHorario: string, horaFechamentoHub: string, isMaringa = true) {
	const horarioDaGrade: Schedule[] = [];

	const horasDeAtuacao = parseInt(horaFechamentoHub) - parseInt(primeiroHorario);
	const maximoAulaDia = Math.floor((horasDeAtuacao * 60) / (isMaringa ? 30 : 15));

	let ultimaHoraInformada = primeiroHorario;
	for (let i = 0; i < maximoAulaDia; i++) {
		const horaInicioSplit = ultimaHoraInformada.split(':');

		const horaFinal = horaFimForm(horaInicioSplit[0], horaInicioSplit[1], isMaringa);

		const horario: Schedule = {
			horaInicial: ultimaHoraInformada,
			horaFinal,
		};

		horarioDaGrade.push(horario);
		ultimaHoraInformada = horaFim(horaInicioSplit[0], horaInicioSplit[1], isMaringa);
	}

	return horarioDaGrade;
}
