import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Hand from '../../assets/img/dashboard/hand.svg';
import NewMenu from '../../components/NewMenu';

import { useGlobalContext } from '../../context/GlobalContext';

import CronogramaDiario from '../../components/CronogramaDiario';
import HomeListTop from '../../components/HomeListTop';
import IndicadoresHome from '../../components/IndicadoresHome';
import { TabSelectedEnum } from '../../enum/home.enum';
import { ILogCleanDTO, IMensagemHomeDTO, IUnidadeHomeDTO } from '../../models/happy-code-api.model';
import { useLogService } from '../../services/log.service';
import { useMensagemHomeService } from '../../services/mensagem-home';
import { useUnidadeService } from '../../services/unidade.service';
import { getObject } from '../../util/store';
import { isGestorOrAdminSede } from '../../util/util';
import ModalMensagemHomeCarrousel from '../MensagemHome/carrousel-mensagem';
import CertificadoComponent from './certificado.component';
import MensagensComponent from './mensagens.component';
import './style.css';

export const Franquia: React.FunctionComponent = () => {
	const { usuario, setIsGlobalLoading, token, unidade } = useGlobalContext();
	const history = useHistory();
	const logService = useLogService();
	const unidadeService = useUnidadeService();
	const [tabSelected, setTabSelected] = useState<TabSelectedEnum>(TabSelectedEnum.lead);
	const [home, setHome] = useState<IUnidadeHomeDTO>();
	const [mensagemHome, setMensagemHome] = useState<IMensagemHomeDTO[]>();
	const mensagemHomeService = useMensagemHomeService();
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);	

	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso tela home franquia',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});
	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const getCurrentDate = () => {
		moment.locale('pt-br');
		const currentDate = moment().format(`DD MMMM YYYY, dddd`);
		return currentDate;
	};

	const findHome = async () => {
		if (unidade?.id) {
			return unidadeService.findHome(unidade?.id).then(({ data }) => setHome(data));
		}
	};

	const acessoExternoCrm = () => {
		window.open(`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRM}?t=${token?.access_token}&u=${unidade?.id}`, '_blank');
	};

	const goToList = () => {
		if (tabSelected === TabSelectedEnum.lead) {
			acessoExternoCrm();
		}
		if (tabSelected === TabSelectedEnum.rematricula) {
			history.push(`/alunos`);
		}
		if (tabSelected === TabSelectedEnum.inadimplente) {
			history.push(`/financeiro`);
		}
	};

	const findMessages = () => {
		setIsGlobalLoading(true);
		mensagemHomeService
			.findAllCustom()
			.then(({ data }) => {
					setMensagemHome(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const checkUser = async () => {
		const result = await isGestorOrAdminSede(usuario, unidade);
		setIsAuthorized(result);
	};


	useEffect(() => {
		setIsGlobalLoading(true);
		const requests = [findHome(), checkUser()];
		if (unidade?.id && usuario?.id) {
			Promise.all(requests).finally(() => setIsGlobalLoading(false));
		}
		if (getObject('modalLogin') === true) {
			findMessages();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id, usuario.id]);

	return (
		<div className="container" style={{ backgroundColor: '#EBEFFC' }}>
			<NewMenu selecionado={1} />
			<ModalMensagemHomeCarrousel content={mensagemHome} />
			<div className="instrutor">
				<div id="franquia" className="dashboard-instrutor">
					<div className="head">
						<div className="info" style={!unidade?.dtVencimento ? { width: '65%' } : {}}>
							<h4>
								<img src={Hand} /> Olá, <strong>{usuario && usuario?.usuario}</strong>
							</h4>
							<h6>Hoje</h6>
							<p>{getCurrentDate()}</p>
						</div>
						{(unidade?.dtVencimento && (isAuthorized || ['financeiro.franquia@happygrupo.com.br'].includes(usuario?.login)))  &&(
							<CertificadoComponent unidade={unidade} />
						)}
						<MensagensComponent />
					</div>
					<div className="body-data">
						<div className="left" style={{ width: '70%' }}>
							<IndicadoresHome kpi={home?.kpi} contratoAtivo={home?.contratosAtivos} />

							<HomeListTop setTabSelected={setTabSelected} tabSelected={tabSelected} topListasHome={home?.topListasHome} />

							<Button style={{ width: '100%' }} className="button-primary" onClick={goToList}>
								Ver Todos
							</Button>
						</div>
						<div className="right" style={{ width: '28%' }}>
							<CronogramaDiario cronogramaAula={home?.cronogramaAula} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
