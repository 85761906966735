import { Button } from 'antd';
import { IMatriculaCustomDTO } from '../../../../../../models/happy-code-api.model';
import CourseCard from '../course-card/course-card.component';
import './student-courses.styles.scss';
import useStudentCourses from './use-student-courses.hook';

interface StudentCoursesProps {
	fetchAlunoRematricula: () => void;
	handleNovoCursoClick: (id: number[]) => void;
	rematriculas: IMatriculaCustomDTO[];
}

export default function StudentCourses({
	fetchAlunoRematricula,
	handleNovoCursoClick,
	rematriculas
}: StudentCoursesProps) {
	const {
		isLoading,
		coursesData,
		isMatricula,
		isRematricula,
		hasUpgradeAvailable,
		handleUpgradeAvailable,
		regularResumes,
		notregularResumes
	} = useStudentCourses(rematriculas);

	return (
		<div className={'student-basic-info-wrapper'}>
			<div className={'student-basic-info-wrapper__title-buttons'}>
				<h4>Cursos</h4>

				<div className={'title-buttons__buttons-container'}>
					{/* {isRematricula && */}
						<Button
							className="button-matricular"
							onClick={() => fetchAlunoRematricula()}
						>
							Renovação
						</Button>
					{/* } */}
					{/* {isMatricula && !isRematricula && !hasUpgradeAvailable && */}
						<Button
							className="button-matricular"
							onClick={() => handleNovoCursoClick(regularResumes)}
						>
							Matrícula Regular
						</Button>
					{/* } */}
					<Button
						className="button-matricular"
						onClick={() => handleNovoCursoClick(notregularResumes)}
					>
						Matrícula Curta/Colônia
					</Button>
				</div>
			</div>
			<div className={'student-basic-info-wrapper__courses-section'}>
				{!isLoading &&
					coursesData &&
					coursesData.map((course) => (
						<CourseCard key={course.id} course={course} onUpgradeAvailable={handleUpgradeAvailable} />
					))}
			</div>
		</div>
	);
}
