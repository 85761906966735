import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {
	IPasswordChangeProfile,
	IPasswordChangeRequest,
	IUnidadePessoaCleanDTO,
	IUserAuthDTO,
	IUsuarioCleanDTO,
	IUsuarioDTO,
	IUsuarioFullDTO,
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFind } from './base/base-find.service';
import { baseFindList } from './base/base-find-list.service';

export const useUsuarioService = () => {
	const resourceURL = 'usuario';

	const getPrincipal = async (): Promise<AxiosResponse<IUserAuthDTO>> => {
		return await http.get<IUserAuthDTO>(resourceURL + '/principal');
	};

	const findTipoUnidadePessoaByParams = async (idUsuario: any, idUnidade: any): Promise<AxiosResponse<IUnidadePessoaCleanDTO>> => {
		return await http.get(`${resourceURL}/find-pessoa-custom/${idUsuario}/${idUnidade}`);
	};

	const recuperaSenha = async (email: string) => {
		return await http.post<void>(resourceURL + `/recupera-senha?login=${email}`, null, {
			headers: {
				Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_PASSWORD_API}`,
			},
		});
	};

	const alteraSenha = async (payload: IPasswordChangeRequest) => {
		return await http.post<void>(resourceURL + `/altera-senha`, payload, {
			headers: {
				Authorization: `Basic ${process.env.REACT_APP_HAPPYCODE_PUBLIC_PASSWORD_API}`,
			},
		});
	};

	const alteraSenhaProfile = async (payload: IPasswordChangeProfile) => {
		return await http.post<void>(resourceURL + `/profile/atualiza-senha`, payload);
	};

	const patchPessoaUsuarioHub = (id: number) => {
		return http.patch(resourceURL + `/patch-pessoa-usuario`, id);
	};

	return {
		findTipoUnidadePessoaByParams,
		getPrincipal,
		recuperaSenha,
		alteraSenha,
		alteraSenhaProfile,
		patchPessoaUsuarioHub,
		...baseCreate<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
		...baseFind<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
		...baseFindList<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
		...baseFindById<IUsuarioCleanDTO, IUsuarioDTO, IUsuarioFullDTO>(resourceURL),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IUsuarioCleanDTO>> => http.patch(`${resourceURL}/full`, toUpdate),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IUsuarioCleanDTO>> => http.patch(`${resourceURL}`, toUpdate),
	};
};
