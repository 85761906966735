import { Button, Input, Space, TableColumnType, TableColumnsType } from 'antd';
import { SearchOutlined } from '@mui/icons-material';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import useRelatorioState from '../../states/use-relatorio-state';
import { RelatorioRematriculaNovoDTO } from '../../../../models/relatorio.model';

const RematriculaSedeTableColumns = () => {
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');

	const {
		selectTipoCurso,
		renderCurrency,
		renderDate,
		selectNegocios,
		findNegocio,
		findTipoCurso,
		selectRematriculaRealizada,
		renderCurrencyList,
		selectStatusCobranca,
		findStatusCobranca,
		selectPossuiData,
		findTipoUnidade,
		selectTipoUnidade,
	} = useRelatorioState();

	useEffect(() => {
		findNegocio();
		findTipoCurso();
		findStatusCobranca();
		findTipoUnidade();
	}, []);

	const handleSearch = (
		selectedKeys: string[],
		confirm: FilterDropdownProps['confirm'],
		dataIndex: string
	) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters: () => void) => {
		clearFilters();
		setSearchText('');
	};

	const getColumnSearchProps = (
		dataIndex: string
	): TableColumnType<RelatorioRematriculaNovoDTO> => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					placeholder={`Buscar ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys as string[], confirm, dataIndex)
					}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys as string[], confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, height: 33 }}
					>
						Buscar
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Resetar
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				.toString()
				.toLowerCase()
				.includes((value as string).toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});
	const columns: TableColumnsType<RelatorioRematriculaNovoDTO> = [
		{
			title: 'Id Contrato',
			dataIndex: 'idContrato',
			sorter: (a, b) => a.idContrato - b.idContrato,
			width: 100,
		},
		{
			title: 'Tipo Unidade',
			dataIndex: 'tipoUnidade',
			filters: selectTipoUnidade,
			onFilter: (value: string, record) => record.tipoUnidade.includes(value),
			width: 150,
		},
		{
			title: 'Nome Unidade',
			dataIndex: 'nomeUnidade',
			...getColumnSearchProps('nomeUnidade'),
			width: 150,
		},
		{
			title: 'Nome Aluno',
			dataIndex: 'nomeAluno',
			...getColumnSearchProps('nomeAluno'),
			width: 180,
		},
		{
			title: 'Negócio',
			dataIndex: 'negocio',
			filters: selectNegocios,
			onFilter: (value: string, record) => record.negocio.includes(value),
			width: 150,
		},
		{
			title: 'Currículo',
			dataIndex: 'curriculo',
			filters: selectTipoCurso,
			onFilter: (value: string, record) => record.curriculo.includes(value),
			width: 100,
		},
		{
			title: 'Forma de Pagamento',
			dataIndex: 'formaPagamento',
			width: 120,
		},
		{
			title: 'Status Cobrança',
			dataIndex: 'statusCobranca',
			width: 120,
			filters: selectStatusCobranca,
			onFilter: (value: string, record) =>
				record.statusCobranca?.includes(value),
		},
		{
			title: 'Data de Pagamento',
			dataIndex: 'dataPagamento',
			render(_value, record, _index) {
				return record.dataPagamentoContrato
					? renderDate(record.dataPagamentoContrato.toString())
					: '';
			},
			width: 130,
			filters: selectPossuiData,
			onFilter: (value: boolean, record) =>
				value
					? record.dataPagamentoContrato !== undefined
					: record.dataPagamentoContrato === undefined,
		},
		{
			title: 'Valor Total com Desconto',
			dataIndex: 'valorTotalComDesconto',
			sorter: (a, b) => a.valorTotalComDesconto - b.valorTotalComDesconto,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorTotalComDesconto);
			},
			width: 150,
		},
		{
			title: 'Total de Parcelas',
			dataIndex: 'totalParcelas',
			sorter: (a, b) => a.totalParcelas - b.totalParcelas,
			width: 120,
		},
		{
			title: 'Valor Mensalidade com Desconto',
			dataIndex: 'valorMensalidadeComDesconto',
			sorter: (a, b) =>
				a.valorMensalidadeComDesconto - b.valorMensalidadeComDesconto,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorMensalidadeComDesconto);
			},
			width: 170,
		},
		{
			title: 'Valor Sede',
			dataIndex: 'valorSede',
			sorter: (a, b) => a.valorSede - b.valorSede,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorSede);
			},
			width: 120,
		},
		{
			title: 'Valor Líquido',
			dataIndex: 'valorLiquido',
			sorter: (a, b) => a.valorLiquido - b.valorLiquido,
			align: 'right',
			render(_value, record, _index) {
				return renderCurrency(record.valorLiquido);
			},
			width: 150,
		},
		{
			title: 'Vencimento do Contrato',
			dataIndex: 'vencimentoContrato',
			sorter: (a, b) =>
				new Date(a.vencimentoContrato).getTime() -
				new Date(b.vencimentoContrato).getTime(),
			render(_value, record, _index) {
				return record.vencimentoContrato
					? renderDate(record.vencimentoContrato.toString())
					: '';
			},
			width: 120,
		},
		{
			title: 'Dias Para Fim',
			dataIndex: 'diasParaFim',
			width: 120,
			sorter: (a, b) => a.diasParaFim - b.diasParaFim,
		},
		{
			title: 'Rematrícula Realizada',
			dataIndex: 'rematriculaRealizada',
			width: 120,
			filters: selectRematriculaRealizada,
			onFilter: (value: string, record) => record.rematriculaRealizada == value,
		},
		{
			title: 'Currículo Rematriculado',
			dataIndex: 'curriculoRematriculado',
			...getColumnSearchProps('curriculoRematriculado'),
			width: 200,
		},
		{
			title: 'Id Contrato Rematrícula',
			dataIndex: 'idContratoRematricula',
			width: 120,
		},
		{
			title: 'Status Rematrícula',
			dataIndex: 'statusRematricula',
			width: 150,
			filters: selectStatusCobranca,
			onFilter: (value: string, record) =>
				record.statusRematricula?.includes(value),
		},
		{
			title: 'Valor da Rematrícula',
			dataIndex: 'valorRematricula',
			align: 'right',
			render(_value, record, _index) {
				return renderCurrencyList(record.valorRematricula);
			},
			width: 150,
		},
	];

	return columns;
};

export default RematriculaSedeTableColumns;
