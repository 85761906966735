import { DatePicker, Table } from 'antd';
import { useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { Predicate } from '../../../../models/predicate.model';
import { useRelatorioService } from '../../../../services/relatorio.service';
import { ExportToExcel } from '../../../../util/exportToExcel';
import { getPeriodoSemPadrao } from '../../../../util/filtroRelatorio';
import moment from 'moment';
import RematriculaSedeTableColumns from './rematricula-sede-table-columns';
import { RelatorioRematriculaNovoDTO } from '../../../../models/relatorio.model';
import IndicadoresRelatorio from '../../../../components/IndicadoresRelatorio';
import { hoje } from '../../../../util/util';

const { RangePicker } = DatePicker;

function RelRematriculaSedeNovo() {
	const { setIsGlobalLoading } = useGlobalContext();
	const [data, setData] = useState<RelatorioRematriculaNovoDTO[]>();
	const service = useRelatorioService();

	const [dataMatriculaInicio, setDataMatriculaInicio] = useState<moment.Moment>(
		moment(hoje()),
	);
	const [dataMatriculaFim, setDataMatriculaFim] = useState<moment.Moment>(
		moment(hoje()),
	);
	

	const [dataVencimentoInicio, setDataVencimentoInicio] =
		useState<moment.Moment>();
	const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>();
	const [dataPagamentoInicio, setDataPagamentoInicio] =
		useState<moment.Moment>();
	const [dataPagamentoFim, setDataPagamentoFim] = useState<moment.Moment>();
	const [displayedData, setDisplayedData] = useState<
		RelatorioRematriculaNovoDTO[]
	>([]);

	useEffect(() => {
		const predicate = new Predicate();
		setIsGlobalLoading(true);
	
		if (dataVencimentoInicio && dataVencimentoFim) {
			predicate.addOption('vencimentoIni', dataVencimentoInicio.toISOString());
			predicate.addOption('vencimentoFim', dataVencimentoFim.toISOString());
		}
	
		if (dataMatriculaInicio && dataMatriculaFim) {
			predicate.addOption('dataMatriculaInicio', dataMatriculaInicio.toISOString());
			predicate.addOption('dataMatriculaFim', dataMatriculaFim.toISOString());
		}
	
		if (dataPagamentoInicio && dataPagamentoFim) {
			predicate.addOption('dataPagamentoInicio', dataPagamentoInicio.toISOString());
			predicate.addOption('dataPagamentoFim', dataPagamentoFim.toISOString());
		}
	
		service.rematriculaNovo(predicate)
			.then(({ data }) => {
				setData(data);
				setDisplayedData(data);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});
	
	}, [
		dataVencimentoInicio,
		dataVencimentoFim,
		dataPagamentoInicio,
		dataPagamentoFim,
		dataMatriculaInicio,
		dataMatriculaFim
	]); // Agora, sempre que um desses valores mudar, a busca será refeita
	

	return (
		<div className="container">
			<NewMenu selecionado={1} />
			<div
				className="dashboard"
				style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
			>
				<IndicadoresRelatorio displayedData={displayedData} />
				<h3 className="title">
					Relatório de Rematrículas
				</h3>
				<div
					className="flex flex-row justify-between items-center gap-10"
					style={{ marginBottom: 10 }}
				>
					<div>
						<span style={{ fontSize: '0.8em' }}>Vencimento Contrato</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataVencimentoInicio(values && values[0]);
								setDataVencimentoFim(values && values[1]);
							}}
							value={getPeriodoSemPadrao(
								dataVencimentoInicio,
								dataVencimentoFim
							)}
							format={'DD-MM-YYYY'}
						/>
					</div>

					<div>
						<span style={{ fontSize: '0.8em' }}>Período de Rematrículas</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataMatriculaInicio(values && values[0]);
								setDataMatriculaFim(values && values[1]);
							}}
							value={[
								dataMatriculaInicio,
								dataMatriculaFim || moment(hoje()),
							]}
							format={'DD-MM-YYYY'}
						/>
					</div>

					<div>
						<span style={{ fontSize: '0.8em' }}>
							Data de Pagamento Contrato
						</span>
						<br />
						<RangePicker
							onChange={(values) => {
								setDataPagamentoInicio(values && values[0]);
								setDataPagamentoFim(values && values[1]);
							}}
							value={[
								dataPagamentoInicio,
								dataPagamentoFim || moment(hoje()), 
							]}
							format={'DD-MM-YYYY'}
						/>
					</div>
					<div>
						<span style={{ fontSize: '0.8em' }}>Excel</span>
						<br />
						<ExportToExcel
							apiData={displayedData}
							fileName={`Matriculas ` + hoje().toISOString()}
						/>
					</div>
				</div>
				<hr style={{ border: 0, backgroundColor: 'gainsboro', height: 1 }} />
				<Table
					dataSource={data}
					columns={RematriculaSedeTableColumns()}
					scroll={{ y: 480 }}
					onChange={(_pagination, _filters, _sorter, extra) => {
						setDisplayedData(extra.currentDataSource);
					}}
				/>
			</div>
		</div>
	);
}

export default RelRematriculaSedeNovo;
