/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { FuncaoEnum } from '../../../enum/funcao.enum';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useUsuarioService } from '../../../services/usuario.service';
import { useArquivoService } from '../../../services/arquivo.service';

export default function usePermissionBaixaHook() {
	const { usuario, tipoUnidade, unidade, setIsGlobalLoading } = useGlobalContext();
	const { findTipoUnidadePessoaByParams } = useUsuarioService();
	const arquivoService = useArquivoService();

	const [canBaixaManual, setCanBaixaManual] = useState<boolean>(false);

	const isGestorByRequest = useCallback(async (): Promise<boolean> => {
		if (usuario.id && unidade.id) {
			setIsGlobalLoading(true);
			const unidadePessoa = (await findTipoUnidadePessoaByParams(usuario.id, unidade.id)).data;
			setIsGlobalLoading(false);
			return unidadePessoa.tipoUnidadePessoaId === 2;
		}
		return false;
	}, [unidade.id, usuario.id]);

	const hasPermissionForBaixaManual = useCallback(async () => {
		const isSedeAndTechOrFinancial =
			usuario.perfil === PerfilEnum.SEDE && usuario.funcao === FuncaoEnum.GESTAO_FINANCEIRO_CODIGO;
		const isUnidadeCB = tipoUnidade.id === 2;
		const isGestor = await isGestorByRequest();
		return isSedeAndTechOrFinancial || (isUnidadeCB && isGestor);
	}, [isGestorByRequest, tipoUnidade.id, usuario.funcao, usuario.perfil]);

	useEffect(() => {
		if (!usuario || !unidade || !tipoUnidade) return;
		const checkPermission = async () => {
			const resultForBaixaManual = await hasPermissionForBaixaManual();
			setCanBaixaManual(resultForBaixaManual);
		};
		checkPermission();
	}, [usuario, unidade, tipoUnidade, hasPermissionForBaixaManual]);

	const handleDownloadBaixa = async (codigoArquivo: string) => {
		if (!codigoArquivo) return;
		const extention = codigoArquivo.split('.')[1];
		const fileName = `nota-baxa.${extention}`
		const mimeType = `application/${extention}`;
		setIsGlobalLoading(true);
		await arquivoService
			.download(codigoArquivo, fileName)
			.then(({ data }) => {
				const url = window.URL.createObjectURL(new Blob([data], {type: mimeType}))
				const tag =	document.createElement('a');
				tag.href = url;
				tag.setAttribute("download", `nota-baixa.${extention}`)
				document.body.appendChild(tag)
				tag.click();
				tag.remove();
				window.URL.revokeObjectURL(url);
			
			})
			.finally(() => setIsGlobalLoading(false));
	};

	return {
		canBaixaManual,
		handleDownloadBaixa,
	};
}
