/* eslint-disable react-hooks/rules-of-hooks */
import { InboxOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import { useState } from 'react';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICobrancaCustomDTO, ILogCleanDTO } from '../../models/happy-code-api.model';
import { useCobrancaService } from '../../services/cobranca.service';
import { useLogService } from '../../services/log.service';
import { useNotificationService } from '../../services/notification.service';
import { disabledDateAfterToday } from '../../util/util';

interface ModalBaixaNew {
	open: boolean;
	onClose: () => void;
	cobranca: ICobrancaCustomDTO;
}

export const ModalBaixaNew = ({ open, onClose, cobranca }: ModalBaixaNew) => {
	if (!open) return null;

	const { setIsGlobalLoading, tipoUnidade } = useGlobalContext();
	const [file, setFile] = useState<File | null>(null);
	const [dataPagamento, setDataPagamento] = useState<Date | null>(moment(Date.now()).toDate());
	const [fileListFake, setFileListFake] = useState<UploadFile[]>([]);
	const logService = useLogService();
	const cobrancaService = useCobrancaService();
	const notification = useNotificationService();

	const asterisco = <span style={{ color: 'red' }}>*</span>;
	const isUnidadeCB = tipoUnidade.id == 2;
	const buildLog = (): ILogCleanDTO => {
		const log = {
			ativo: true,
			descricaoEvento: `Baixa manual realizada para cobrança ID ${cobranca.id} + com arquivo: ${file ? file.name : 'NULL'}`,
			descricaoTipo: 'Baixa Manual',
			id: null,
			descricaoUsuario: null,
			idTipo: null,
		};

		return log;
	};

	const enviarBaixa = () => {
		setIsGlobalLoading(true);
		cobrancaService
			.baixaManualCobranca(file, cobranca?.id, dataPagamento)
			.then(() => {
				logService.registraLog(buildLog());
			})
			.then(() => {
				notification({
					description: 'Cobrança atualizada com sucesso.',
					message: 'Sucesso!',
					type: 'success',
				});
			})
			.catch((err) => {
				console.log(err);

				setIsGlobalLoading(false);
				return;
			})
			.finally(() => {
				onClose();
				setIsGlobalLoading(false);
			});
	};
	const handleBeforeUpload = (file: File) => {
		const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];

		if (!allowedTypes.includes(file.type)) {
			alert('Formato de arquivo inválido. Apenas arquivos PNG, JPEG, ou PDF são permitidos." ');
			const newFile: UploadFile = {
				uid: String(Date.now()),
				name: file.name,
				status: 'error',
				response: `A extenção ".${file.name.split('.').pop()}" não é suportada! Apenas PNG, JPEG ou PDF são permitidos.`,
			};
			setFileListFake([newFile]);
			return false;
		}
		setFile(file);
		const newFile: UploadFile = {
			uid: String(Date.now()),
			name: file.name,
			status: 'done',
		};
		setFileListFake([newFile]);
		return false;
	};

	const onRemoveFile = () => {
		setFileListFake([]);
		setFile(null);
	};

	const handleDateChange = (date: moment.Moment | null) => {
		if (!date) {
			setDataPagamento(null);
		} else {
			setDataPagamento(date.toDate());
		}
	};

	const validateForm = () => {
		if (isUnidadeCB) {
			return !dataPagamento;
		}
		return !file || !dataPagamento;
	};

	return (
		<div className="modalContainerFinanceiro">
			<div className="modal-user-financeiro">
				<div className="header-modal-baixa">
					<h3>Baixa Manual de Cobrança</h3>
					<small>
						Parcela nº {cobranca.parcela} - Vencimento: {moment(cobranca.dataVencimento).format('DD/MM/YYYY')}
					</small>
				</div>
				<div className="input-box" id="box-aluno">
					<span>Comprovante de pagamento {!isUnidadeCB ? asterisco : null}</span> <br />
					<Dragger
						maxCount={1}
						onRemove={onRemoveFile}
						style={{ alignItems: 'center', padding: '0px 18px' }}
						beforeUpload={(file) => handleBeforeUpload(file)}
						fileList={fileListFake}
					>
						<p className="ant-upload-drag-icon">
							<InboxOutlined rev={null} />
						</p>
						<p className="ant-upload-text">Clique ou arraste o comprovante de pagamento para aqui para fazer o upload</p>
						<p className="ant-upload-hint">Somente arquivos PNG, JPEG ou PDF são aceitos.</p>
					</Dragger>
				</div>
				<div style={{ paddingLeft: 24, paddingRight: 24 }}>
					<p>Data de pagamento {asterisco}</p>
					<DatePicker
						size={'large'}
						format={'DD/MM/YYYY'}
						defaultValue={moment(Date.now())}
						onChange={(e) => handleDateChange(e)}
						disabledDate={disabledDateAfterToday}
						getPopupContainer={(triggerNode) => {
							return triggerNode.parentElement;
						}}
					/>
				</div>
				<div className="botoes-modal-baixa">
					<Button className="button-primary" style={{ width: '145px' }} onClick={onClose}>
						Voltar
					</Button>
					<Button className="button-primary" disabled={validateForm()} onClick={enviarBaixa} style={{ width: '145px' }}>
						Confirmar Baixa
					</Button>
				</div>
			</div>
		</div>
	);
};
