import { Modal } from 'antd';

export default function useTratamentoErroPagamento() {
	const validarErroCartao = (acquirerReturnCode: string) => {
		switch (acquirerReturnCode) {
			case '1000':
				Modal.error({
					content: 'Transação não autorizada, contate o banco/emissor do cartão',
					title: 'Aviso!',
				});
				break;
			case '1001':
				Modal.error({
					content: 'Pagamento não autorizado (Cartão vencido, a data de vencimento do cartão expirou)',
					title: 'Aviso!',
				});
				break;
			case '1002':
				Modal.error({
					content: 'Cartão com suspeita de fraude',
					title: 'Aviso!',
				});
				break;
			case '1008':
				Modal.error({
					content: 'Transação recusada pelo banco, oriente o portador a contatar o banco/emissor',
					title: 'Aviso!',
				});
				break;
			case '1009':
				Modal.error({
					content: 'Transação recusada pelo banco, oriente o portador a contatar o banco/emissor',
					title: 'Aviso!',
				});
				break;
			case '1011':
				Modal.error({
					content: 'Número do cartão inválido. Oriente o usuário a verificar os dados.',
					title: 'Aviso!',
				});
				break;
			case '1016':
				Modal.error({
					content: 'Pagamento não autorizado (Saldo insuficiente)',
					title: 'Aviso!',
				});
				break;
			case '1019':
				Modal.error({
					content: 'Sistema de prevenção do banco não autorizou a compra. Oriente o usuário a contatar o banco/emissor do cartão',
					title: 'Aviso!',
				});
				break;
			case '1022':
				Modal.error({
					content: 'Transação não autorizada por violação de segurança.',
					title: 'Aviso!',
				});
				break;
			case '1029':
				Modal.error({
					content: 'Cartão com suspeita de falsificação!',
					title: 'Aviso!',
				});
				break;
			case '1050':
				Modal.error({
					content: 'Banco/emissor do cartão não operante no momento.',
					title: 'Aviso!',
				});
				break;
			case '1061':
				Modal.error({
					content: 'Transação não suportada para este banco/emissor do cartão',
					title: 'Aviso!',
				});
				break;
			case '1070':
				Modal.error({
					content: 'Número do documento de identificação do usuário inválido.',
					title: 'Aviso!',
				});
				break;
			case '1087':
				Modal.error({
					content: 'Transação não autorizada. Limite diário excedido.',
					title: 'Aviso!',
				});
				break;
			case '1093':
				Modal.error({
					content: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido.',
					title: 'Aviso!',
				});
				break;
			case '2000':
				Modal.error({
					content: 'Transação não autorizada. Oriente o portador a contatar o banco/emissor do cartão.',
					title: 'Aviso!',
				});
				break;
			case '2001':
				Modal.error({
					content: 'Cartão vencido ou data de vencimento incorreta. Oriente o usuário a verificar o cartão.',
					title: 'Aviso!',
				});
				break;
			case '2002':
				Modal.error({
					content: 'Transação com suspeita de fraude.',
					title: 'Aviso!',
				});
				break;
			case '2004':
				Modal.error({
					content: 'Cartão com restrição. Oriente o usuário a contatar o banco/emissor do cartão.',
					title: 'Aviso!',
				});
				break;
			case '2006':
				Modal.error({
					content: 'O número de tentativas de senha foi excedido.',
					title: 'Aviso!',
				});
				break;
			case '2007':
				Modal.error({
					content: 'Transação recusada pelo banco. Oriente o portador a contatar o banco/emissor do cartão.',
					title: 'Aviso!',
				});
				break;
			case '2009':
				Modal.error({
					content: 'Cartão bloqueado por perda ou roubo.',
					title: 'Aviso!',
				});
				break;
			case '2010':
				Modal.error({
					content: 'Cartão com suspeita de falsificação.',
					title: 'Aviso!',
				});
				break;
			case '2012':
				Modal.error({
					content: 'Limite diário de saques excedido.',
					title: 'Aviso!',
				});
				break;
			case '5025':
				Modal.error({
					content: 'Código de segurança (CVV) do cartão inválido.',	
					title: 'Aviso!',
				});
				break;
			case '9112':
				Modal.error({
					content: 'Falha de comunicação com o banco/emissor do cartão. Tente novamente.',
					title: 'Aviso!',
				});
				break;
			case '9200':
				Modal.error({
					content: 'Transação recusada permanentemente. Não tente novamente.',
					title: 'Aviso!',
				});
				break;
			case '9201':
				Modal.error({
					content: 'Transação recusada por excesso de tentativas.',
					title: 'Aviso!',
				});
				break;
			default:
				Modal.error({
					content: `Pagamento não autorizado. Código: ${acquirerReturnCode ?? '500'} `,
					title: 'Aviso!',
				});
				break;
		}
	};

	return {
		validarErroCartao,
	};
}
